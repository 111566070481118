import React from 'react'
import styled from 'styled-components'
import infoIcon from '../../images/info.svg'
import media from '../../styles/media'
import { Copy } from '../Text'

const Info = styled.div`
  display: ${props => (props.isOpen ? 'flex' : 'none')};
  flex-direction: column;
  padding: 1.6rem;
  background-color: white;
`

const InfoGraphWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1.6rem;
`

const InfoGraph = styled.div`
  background: linear-gradient(
    to bottom,
    #00bd38,
    #95c11f,
    #d9e075,
    #ffde00,
    #ee7904
  );
  border-radius: 16px;
  background-color: red;
  height: 7.9rem;
  width: 3.2rem;
  ${media.down.sm`
    border-radius: 12px;
    height: 6.4rem;
    width: 2.4rem;
  `}
`

const Labels = styled.div`
  margin-left: 1.6rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Button = styled.div`
  display: inline-block;
  padding: 1.6rem;
  background-color: white;
  cursor: pointer;
`

const InfoIcon = styled.img`
  ${media.down.sm`
  max-width: 3.2rem;
  `}
`

const MapInfoButton = ({ isOpen, onClick }) => (
  <>
    <Info isOpen={isOpen}>
      <Copy>Einsparpotenzial in Jahren</Copy>
      <InfoGraphWrapper>
        <InfoGraph></InfoGraph>
        <Labels>
          <Copy>groß</Copy>
          <Copy>klein</Copy>
        </Labels>
      </InfoGraphWrapper>
    </Info>
    <Button onClick={onClick}>
      <InfoIcon src={infoIcon} alt="Info öffnen" />
    </Button>
  </>
)

export default MapInfoButton
