import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { space } from 'styled-system'
import media from '../../styles/media'
import { Copy } from '../Text'
import Quote from '../../images/quote.svg'

const Content = styled.div`
  ${space}
`

const Avatar = styled(Img)`
  width: 14.4rem;
  height: 14.4rem;
  border-radius: 50%;
  overflow: hidden;
`

const Icon = styled.img`
  width: 5.6rem;
  height: 5.6rem;
  position: absolute;
  top: 0;
  right: -3rem;
`

const AvatarWrapper = styled.div`
  ${space}
  display: flex;
  position: relative;
  align-self: center;
  ${media.up.md`
  align-self: flex-start;
  `}
`

const Wrapper = styled.div`
  cursor: grab;
  display: flex;
  flex-direction: column;
  background: white;
  /* box-shadow: 4px 4px 1px 0 #bfdfe2; */
  padding: 3.2rem 1.6rem;
  margin: 0 4px 4px 0;
  ${media.up.sm`
  padding: 3.2rem 4rem;
  `}
  ${media.up.md`
  flex-direction: row;
  padding: 3.2rem 6.4rem;
  `}
  &:active {
    cursor: grabbing;
  }
  height: 27rem;
  ${media.down.xl`
    height: 31rem;
  `}
  ${media.down.lg`
    height: 43rem;
  `}
  ${media.down.md`
    height: 56rem;
  `}
  ${media.down.sm`
    height: 60rem;
  `}
`

const Testimonial = ({ avatar, name, city, consultant, text }) => (
  // console.log('img', avatar)
  <Wrapper>
    <AvatarWrapper>
      <Avatar fluid={avatar} />
      <Icon src={Quote} />
    </AvatarWrapper>
    <Content mt={{ xxs: 4, md: 0 }} ml={{ xxs: 0, md: 6 }}>
      <Copy as="div">{text}</Copy>
      <Copy as="div" mt={{ xxs: '2.8rem', md: 4 }}>
        <strong>
          - {name}, {city}
        </strong>
      </Copy>
      {consultant && (
        <Copy as="div" mt={{ xxs: '2.8rem', md: 4 }}>
          <Copy>Die Beratung erfolgte durch Interhyp-Berater {consultant}</Copy>
        </Copy>
      )}
    </Content>
  </Wrapper>
)

export default Testimonial
