import React from 'react'
import styled, { css } from 'styled-components'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { Collapse } from 'react-collapse'
import media from '../../styles/media'
import Checkmark from '../../images/checkmark1.svg'

const Headbar = styled.div`
  height: 5.6rem;
  width: 100%;
  background-color: #f7f6f4;
  border-bottom: 1px solid #dddddd;
  display: flex;
  flex-direction: row;
  ${({ disabled }) =>
    !disabled &&
    css`
      cursor: pointer;
    `}
`

const Wrapper = styled.div``

const Number = styled.div`
  width: 5.6rem;
  background-color: ${props =>
    props.type == 'active'
      ? props.theme.colors.highlight
      : props.type == 'disabled'
      ? '#CCCCCC'
      : props.type == 'success'
      ? props.theme.colors.success
      : '#CCCCCC'};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-weight: bold;
`

const Headline = styled.div`
  display: flex;
  width: 100%;
  font-weight: bold;
  align-items: center;
  justify-content: space-between;
  padding-left: 1.6rem;
  padding-right: 2rem;
  color: ${props =>
    props.type == 'active'
      ? props.theme.colors.highlight
      : props.type == 'disabled'
      ? '#CCCCCC'
      : props.type == 'success'
      ? props.theme.colors.success
      : '#CCCCCC'};
`

const StepsList = ({
  number,
  id,
  headline,
  type,
  isOpened,
  children,
  onClick,
}) => (
  <Wrapper id={id} onClick={onClick}>
    <Headbar>
      <Number type={type}>{number}</Number>
      <Headline type={type}>
        {headline}
        {type === 'success' && <img src={Checkmark} alt="checkmark.svg" />}
      </Headline>
    </Headbar>
    <Collapse isOpened={isOpened}>{children}</Collapse>
  </Wrapper>
)

export default StepsList
