import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import media from '../styles/media'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Newsletter from '../components/Newsletter'
import About from '../components/About'
import USP from '../components/USP'
import Consulting from '../components/Consulting'
import Section from '../components/Section'
import Slider from '../components/Slider'
import FAQ from '../components/FAQ'
import Map from '../components/Map'
import Testimonials from '../components/Testimonials'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Tilgungsrechner from '../components/Tilgungsrechner'

const Box = styled.div`
  &:nth-of-type(even) {
    background-color: ${props => props.theme.colors.secondary};
  }
`

const IndexPage = () => {
  useEffect(() => console.log('mounted'), [])
  return (
    <Layout>
      <SEO title="Interhyp" keywords={[`Interhyp`, `Baufinanzierung`, `Schuldenfrei-Tag`]} />
      <Box id="restschulden">
        <Map />
      </Box>
      <Box id="anschlussfinanzierung">
        <USP />
      </Box>
      <Box id="faq">
        <FAQ />
      </Box>
      <Box id="tilgungsrechner">
        <Tilgungsrechner />
      </Box>
      <Box id="kundenberatung">
        <Consulting />
      </Box>
      <Box id="testimonials">
        <Testimonials />
      </Box>
      <Box id="interhyp">
        <About />
      </Box>
      <Box id="newsletter">
        <Newsletter />
      </Box>
      <Footer />
    </Layout>
  )
}

export default IndexPage
