import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { space } from 'styled-system'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import media from '../../styles/media'
import { Copy } from '../Text'
import Section from '../Section'

const Image = styled(Img)``

const Spacer = styled.div`
  ${space}
`

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "about_interhyp.jpg" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 445, maxHeight: 334, cropFocus: CENTER) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Section
      background={props => props.theme.colors.secondary}
      section="interhyp"
      headline="Über Interhyp"
    >
      <Container>
        <Row>
          <Col col={12} md={5}>
            <Image
              fluid={data.file.childImageSharp.fluid}
              alt="Über Interhyp"
            />
          </Col>
          <Col col={12} md={6} mdOffset={1}>
            <Spacer mt={{ xxs: 4, md: 0 }} />
            <Copy>
              Interhyp ist Deutschlands gr&ouml;&szlig;ter Vermittler f&uuml;r
              private Baufinanzierungen. Mit unseren ausgebildeten
              Finanzierungsexperten unterst&uuml;tzen wir Sie dabei, die
              perfekte Finanzierungsstrategie zu entwickeln.
              <br />
              <br />
              Interhyp wurde 1999 gegr&uuml;ndet und hat seitdem schon mehr als
              800.000 Menschen erfolgreich bei der Finanzierung ihrer Immobilie
              unterst&uuml;tzt &ndash; und bald auch Sie? Wir passen Ihre
              Finanzierung Ihren individuellen Bed&uuml;rfnissen an und greifen
              dabei auf die Angebote von mehr als 400 Kreditgebern zu. Mit mehr
              als 100 Standorten in ganz Deutschland ist Interhyp auch in Ihrer
              N&auml;he f&uuml;r Sie da.
            </Copy>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

export default About
