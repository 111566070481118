import React from 'react'
import styled from 'styled-components'
import { H3, H2, Copy as copy, Badge } from '../Text'
import Checkmark from '../../images/checkmark1.svg'
import media from '../../styles/media'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const ResultWrapper = styled.div`
  display: flex;
  ${media.down.md`
    flex-direction: column;
  `}
`

const Head = styled.div`
  background-color: ${props => props.colorProp};
  padding: 1.6rem 3.2rem;
  > span {
    color: ${props => props.fontColor && props.fontColor};
  }
`
const ResultBox = styled.div`
  width: 100%;
  background-color: ${props => props.colorProp};
  box-shadow: ${({ shadow }) =>
    shadow && '0px 0px 16px 0px rgba(51, 51, 51, 0.3)'};
  > *:not(${Head}) span {
    color: ${props => props.fontColor && props.fontColor};
  }
  ${media.down.md`
    order: ${props => props.order && props.order}
    z-index: ${({ order }) => order && 10 - order}
  `}
`

const Content = styled.div`
  padding: 1.6rem 3.2rem;
  display: flex;
  flex-direction: column;
`

const StyledH2 = styled(H2)`
  margin-top: 0.9rem;
`

const Divider = styled.div`
  height: 1px;
  width: 100%;
  margin-top: 1.6rem;
  background-color: #ddd;
`

const DebtFree = styled.div`
  min-height: 10.2rem;
  display: flex;
  flex-direction: column;
  ${props => media.down.md`
  min-height: ${props.old && '5.4rem'}
  `}
`

const Payed = styled.div`
  margin-top: 1.6rem;
  min-height: 10.2rem;
  display: flex;
  flex-direction: column;
  ${props => media.down.md`
    min-height: ${props.old && '5.4rem'}
  `}
`

const BiggerH2 = styled(H2)`
  font-size: 3.6rem !important;
  margin-top: 0.9rem;
`

const Improvement = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1.3rem;
`

const ImprovementText = styled(Badge)`
  margin-left: 1.6rem;
`

const Copy = styled(copy)`
  line-height: 1 !important;
`

const Footnote = styled.div`
  margin-top: 0.8rem;
  line-height: 1.8rem;
  font-size: 1.2rem;
`

const Result = ({
  debtFree,
  payed,
  savedTime,
  savedMoney,
  className,
  error = false,
}) => (
  <Wrapper className={className}>
    <ResultWrapper>
      <ResultBox colorProp={props => props.theme.colors.background} order={2}>
        <Head colorProp="#EEE">
          <H3>Fortsetzung zu alten Bedingungen</H3>
        </Head>
        <Content>
          <DebtFree old>
            <Copy>Schuldenfrei-Tag</Copy>
            <StyledH2>
              <strong>{debtFree[0]}</strong>
            </StyledH2>
          </DebtFree>
          <Divider />
          <Payed old>
            <Copy>Gezahlte Gesamtsumme</Copy>
            <StyledH2>
              <strong>{payed[0]}</strong>
            </StyledH2>
          </Payed>
        </Content>
      </ResultBox>
      <ResultBox
        fontColor={props =>
          props.error ? props.theme.colors.error : props.theme.colors.success
        }
        order={1}
        shadow
      >
        <Head
          error={error}
          colorProp={props =>
            props.error ? props.theme.colors.error : props.theme.colors.success
          }
          fontColor="white"
        >
          <H3>Anschlussfinanzierung mit optimierten Bedingungen</H3>
        </Head>
        <Content>
          <DebtFree>
            <Copy>Schuldenfrei-Tag</Copy>
            <BiggerH2>
              <strong>{debtFree[1]}</strong>
            </BiggerH2>
            <Improvement>
              {savedTime >= 1 ? <img src={Checkmark} /> : 'X'}
              <ImprovementText>
                Sie sind{' '}
                {Math.abs(savedTime) >= 12 &&
                  `${Math.floor(Math.abs(savedTime) / 12)} Jahr${
                    savedTime >= 24 || savedTime <= -24 ? 'e' : ''
                  } und `}
                {savedTime % 12 !== 0 &&
                  `${Math.abs(savedTime) % 12} Monat${
                    Math.abs(savedTime) % 12 > 1 ? 'e' : ''
                  } `}
                {savedTime >= 1 ? 'früher' : 'später'} fertig *
              </ImprovementText>
            </Improvement>
          </DebtFree>
          <Divider />
          <Payed>
            <Copy>Gezahlte Gesamtsumme</Copy>
            <BiggerH2>
              <strong>{payed[1]}</strong>
            </BiggerH2>
            <Improvement>
              <img src={Checkmark} />
              <ImprovementText>Sie sparen {savedMoney} *</ImprovementText>
            </Improvement>
          </Payed>
        </Content>
      </ResultBox>
    </ResultWrapper>
    <Footnote>* im Vergleich zur Fortsetzung zu alten Bedingungen</Footnote>
  </Wrapper>
)

export default Result
