import React, { useState } from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { Form, Field } from 'react-final-form'
import {
  parse,
  addMonths,
  addYears,
  subYears,
  subMonths,
  startOfMonth,
  format,
  differenceInMonths,
  differenceInHours,
  lastDayOfMonth,
  isBefore,
} from 'date-fns'
import numeral from 'numeral'
import { OnChange } from 'react-final-form-listeners'
import de from 'date-fns/locale/de'
import { differenceInYears } from 'date-fns/esm'
import Scrollchor from 'react-scrollchor'
import { Copy, H3 } from '../Text'
import media from '../../styles/media'
import Section from '../Section'
import StepsList from '../StepsList'
import { FinalFormInput } from '../Input'
import Button from '../Buttons'
import MoreInfo from '../MoreInfo'
import {
  calcInterestPayment,
  formatEuro,
  formatPercentage,
  calcMinRate,
  getInterest,
  getCity,
} from '../../helper'
import Range from '../Range'
import Results from '../Results'
import Spinner from '../Spinner'

require('numeral/locales/de')

numeral.locale('de')
numeral.localeData('de').delimiters.thousands = '.'

const Wrapper = styled.div``

const Content = styled.div`
  padding: 4.8rem 3.2rem 4.8rem 3.2rem;
  background: white;
  width: 100%;
  ${media.down.lg`
    padding: 3.2rem 1.6rem;
  `}
`

const StyledMoreInfo = styled(MoreInfo)`
  margin-left: 3.2rem;
  margin-top: 1.2rem;
  flex-shrink: 0;
  ${media.down.lg`
    margin-left: 1.6rem;
  `}
`

const InputField = styled.div`
  display: flex;
  flex-basis: 50%;
  > div:first-of-type {
    max-width: 33rem;
  }
  ${media.down.lg`
    > div:first-of-type {
      max-width: 100%;
    }
    > div:only-child {
      padding-right: 4.8rem;
    }
    justify-content: space-between;
  `}
`

const InputFields = styled.div`
  margin-top: calc(3.2rem - 2.4rem);
  max-width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-shrink: 1;
  & > * {
    margin-top: 2.4rem;
  }
  ${media.down.lg`
    flex-direction: column;
  `}
`

const ButtonWrapper = styled.div`
  margin-top: 3.2rem;
  display: flex;
  justify-content: center;
  & > * {
    width: 33rem;
  }
  ${media.down.sm`
    & > * {
      width: 100%;
    }
  `}
`

const StyledCopy = styled(Copy)`
  font-size: 1.6rem;
  ${media.down.sm`
    font-size: 1.4rem;
  `}
`

const Sliders = styled.div`
  margin-top: 3.2rem;
  display: flex;
  justify-content: space-between;
  ${media.down.md`
    flex-direction: column;
  `}
`

const Slider = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  &:not(:first-child) {
    margin-left: 6rem;
  }
  ${media.down.md`
    &:not(:first-child) {
      margin: 0;
      margin-top: 3.2rem;
    }
  `}
`

const StyledRange = styled(Range)`
  margin-top: 1.6rem;
`

const Line = styled.div`
  height: 1px;
  width: 100%;
  background-color: #eee;
  margin-top: 3.3rem;
`

const StyledResults = styled(Results)`
  margin-top: 3.2rem;
`

const InterestOfferBox = styled.div`
  margin-top: 1.6rem;
`

const InterestOffer = styled.div`
  font-weight: bold;
  font-size: 3.6rem;
  ${media.down.md`
    font-size: 3rem;
  `}
`

const CopyText = () => (
  <>
    Wollen Sie genauer wissen, welchen Tag Sie sich in Ihrem Kalender als Ihren
    Schuldenfrei-Tag markieren d&uuml;rfen? Ermitteln Sie jetzt mit Ihren
    eigenen Daten, wie gro&szlig; Ihr finanzielles und zeitliches Sparpotenzial
    sein k&ouml;nnte &ndash; ganz einfach in nur drei Schritten. Probieren Sie
    es gleich aus!
    <br />
    <br />
    Disclaimer: Das Ergebnis des Tilgungsrechners kann nur einen
    N&auml;herungswert darstellen. Ein individuelles Beratungsgespr&auml;ch zu
    Ihrer Anschlussfinanzierung mit Ihrem Interhyp Fachmann wird empfohlen.
  </>
)

const Tilgungsrechner = () => {
  const [trackingStep1, setTrackingStep1] = useState(null)
  const [trackingStep2, setTrackingStep2] = useState(null)
  const [trackingStep3, setTrackingStep3] = useState(null)

  const [step2, setStep2] = useState(null)
  const [step3, setStep3] = useState(null)

  const [initialDuration, setInitialDuration] = useState(5)

  const [date, setDate] = useState(null)

  const [remainingDebt, setRemainingDebt] = useState(null)
  const [payedSumAfterPeriod, setPayedSumAfterPeriod] = useState(null)

  const [frequency, setFrequency] = useState(null)

  const [payedSum, setPayedSum] = useState(null)
  const [newPayedSum, setNewPayedSum] = useState(null)

  const [finishDate, setFinishDate] = useState(null)
  const [newFinishDate, setNewFinishDate] = useState(null)

  const [activePhase, setActivePhase] = useState(0)
  const [finishedPhase, setFinishedPhase] = useState([])

  const [rate, setRate] = useState(1000)
  const [interest, setInterest] = useState(0.01)
  const [duration, setDuration] = useState(null)
  const [estateValue, setEstateValue] = useState(null)
  const [zip, setZip] = useState(null)

  const [loading, setLoading] = useState(false)
  // Form formatting

  const formatPrice = value => numeral(value).format('0,0$')

  const formatPercent = value => value && value.replace('%', '').concat('%')

  const numRegEx = /[^0-9,]/
  const onlyNumber = v => v && v.replace(numRegEx, '')

  // Form Validation

  const composeValidators = (...validators) => value =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    )

  const validateZip = async v => {
    if (v.length === 5) {
      const city = await getCity(v)

      return city.placeName ? undefined : 'Invalid city.'
    }
    return 'Please enter city.'
  }

  const validateDate = (v, y) => {
    const date = addYears(parse(v, 'dd.MM.yyyy', new Date()), y)
    const maxDate = addYears(new Date(), 4)
    return differenceInHours(date, maxDate) > 0
  }

  const minValue = min => value => {
    // console.log(value)
    // const prefix =
    //   typeof value === 'string' && value.includes('%')
    //     ? '%'
    //     : value.includes('€')
    //     ? '€'
    //     : ''
    // value = formatPercentage(formatEuro(value))
    // return isNaN(value) || value >= min
    //   ? undefined
    //   : `Should be greater than ${min}${prefix && prefix}`
  }

  const required = value =>
    value ? undefined : 'Bitte füllen Sie dieses Pflichtfeld aus.'

  const validateForm = ({
    loan,
    remainingDebt,
    interest,
    frequency,
    rate,
    duration,
    date,
  }) => {
    const errors = {}
    const formattedRate = formatEuro(rate)
    const min = calcMinRate(loan || remainingDebt, interest, frequency, rate)

    errors.rate =
      formattedRate < min
        ? `Die ${frequency.label.toLowerCase()}e Rate darf nicht unter ${parseFloat(
            min.toFixed(2)
          )}€ liegen.`
        : undefined

    errors.date =
      activePhase === 0 &&
      validateDate(
        date,
        duration && (duration.value > 10 ? 10 : duration.value)
      )
        ? 'Das Auszahlungsdatum darf nicht über 4 Jahre in der Zukunft liegen.'
        : undefined
    errors.date =
      activePhase === 0 &&
      isBefore(
        subMonths(
          subYears(
            new Date(),
            (initialDuration > 10 ? 10 : initialDuration) - 4
          ),
          initialDuration > 10 ? 6 : 0
        ),
        parse(date, 'dd.MM.yyyy', new Date())
      )
        ? 'Bitte überprüfen Sie das Datum'
        : undefined
    return errors
  }

  const tracking = step => {
    if (utag) {
      switch (step) {
        case 1:
          if (!trackingStep1) {
            setTrackingStep1(true)
            utag.link({
              calculator_name: 'Tilgungsrechner_asf',
              calculator_step: 'start',
            })
          }
          break
        case 2:
          if (!trackingStep2) {
            setTrackingStep2(true)
            utag.link({
              calculator_name: 'Tilgungsrechner_asf',
              calculator_step: 'anschlussfinanzierung',
            })
          }
          break
        case 3:
          if (!trackingStep3) {
            setTrackingStep3(true)
            utag.link({
              calculator_name: 'Tilgungsrechner_asf',
              calculator_step: 'results',
            })
          }
          break
        default:
          break
      }
    }
  }

  const onSubmit = ({
    date,
    interest,
    frequency,
    duration,
    loan,
    rate,
    values,
    zip,
    estateValue,
  }) => {
    tracking(2)
    const formattedLoan = loan && formatEuro(loan)
    const formattedDate = parse(date, 'dd.MM.yyyy', new Date())
    const formattedInterest = formatPercentage(interest)
    const formattedRate = rate && formatEuro(rate)
    const formattedEstateValue = estateValue && formatEuro(estateValue)
    duration = duration.value
    const result = calcInterestPayment(
      parseFloat(formattedLoan),
      parseFloat(formattedInterest),
      parseInt(formattedRate),
      frequency.value,
      formattedDate,
      parseInt(duration > 10 ? 10 : duration)
    )
    if (result.restAfterPeriod <= 0) {
      return { loan: 'Keine Restschuld vorhanden.' }
    }
    if (result.restAfterPeriod < formattedRate) {
      return { loan: 'Restschuld ist kleiner als die Rate' }
    }
    if (result.date === undefined) {
      return { date: 'something went wrong' }
    }
    setEstateValue(formattedEstateValue)
    const unboundDate = startOfMonth(
      addYears(formattedDate, duration > 10 ? 10 : duration)
    )
    setDate(
      format(
        isBefore(unboundDate, new Date())
          ? new Date()
          : addYears(
              addMonths(formattedDate, duration > 10 ? 6 : 0),
              duration > 10 ? 10 : duration
            ),
        'dd.MM.yyyy'
      )
    )
    setFrequency(frequency)
    setRate(formattedRate)
    setRemainingDebt(
      result.restAfterPeriod &&
        result.restAfterPeriod.toFixed(2).replace('.', ',')
    )
    setInterest(formattedInterest)
    setPayedSum(result.debtSum)
    setPayedSumAfterPeriod(result.debtSumAfterPeriod)
    setFinishDate(
      format(lastDayOfMonth(result.date), 'dd. MMMM yyyy', {
        locale: de,
      })
    )
    setZip(zip)
    setFinishedPhase([...finishedPhase, activePhase])
    setActivePhase(activePhase + 1)
    setTimeout(() => {
      step2.simulateClick()
    }, 600)
  }

  const onSecondSubmit = async ({
    interest,
    duration,
    remainingDebt,
    extra,
  }) => {
    tracking(3)
    const formattedLoan = remainingDebt && formatEuro(remainingDebt)
    const formattedRate = rate && formatEuro(rate)
    const formattedDate = parse(date, 'dd.MM.yyyy', new Date())
    const formattedExtra = extra && formatEuro(extra)
    duration = duration.value
    setLoading(true)

    const localInterest = await getInterest(
      parseInt(zip),
      estateValue,
      startOfMonth(formattedDate),
      formattedLoan,
      parseInt(duration),
      parseInt(2)
    )
      .then(res => res[0].effectiveInterest)
      .catch(error => {
        setLoading(false)
        console.error(error)
      })
    if (!localInterest) {
      return {
        remainingDebt:
          'Es ist ein Fehler bei der Berechnung passiert. Bitte prüfen Sie Ihre Angaben.',
      }
    }
    const result = calcInterestPayment(
      parseFloat(formattedLoan),
      localInterest / 100,
      rate,
      frequency.value,
      formattedDate,
      parseInt(duration)
    )
    setNewPayedSum(result.debtSum + payedSumAfterPeriod)
    setNewFinishDate(
      format(lastDayOfMonth(result.date), 'dd. MMMM yyyy', {
        locale: de,
      })
    )
    setDuration(duration)
    setInterest(localInterest)

    setFinishedPhase([...finishedPhase, activePhase])
    setActivePhase(activePhase + 1)
    setLoading(false)
    setTimeout(() => {
      step3.simulateClick()
    }, 600)
  }

  const calcNew = ({ rate }) => {
    const formattedLoan = remainingDebt && formatEuro(remainingDebt)
    const formattedRate = formatEuro(rate)
    const formattedDate = parse(date, 'dd.MM.yyyy', new Date())
    const result = calcInterestPayment(
      parseFloat(formattedLoan),
      parseFloat(interest / 100),
      parseInt(formattedRate),
      frequency.value,
      formattedDate,
      parseInt(duration)
    )
    setNewPayedSum(result.debtSum + payedSumAfterPeriod)
    setNewFinishDate(
      format(lastDayOfMonth(result.date), 'dd. MMMM yyyy', {
        locale: de,
      })
    )
  }

  const minRate = calcMinRate(
    remainingDebt,
    interest,
    frequency,
    rate
  ).toFixed()

  return (
    <Section
      background={props => props.theme.colors.secondary}
      section="Tilgungsrechner"
      headline="Berechnen Sie jetzt Ihren persönlichen Schuldenfrei-Tag!"
      copy={<CopyText />}
    >
      <Wrapper>
        <Container>
          <Row>
            <Col xl={10} xlOffset={1} md={12}>
              <StepsList
                id="step1"
                type={
                  (activePhase === 0 && 'active') ||
                  (finishedPhase.includes(0) && 'success')
                }
                number={1}
                headline="Meine aktuelle Finanzierung"
                isOpened={activePhase === 0}
                onClick={() => finishedPhase.includes(0) && setActivePhase(0)}
              >
                <Content>
                  <StyledCopy>
                    Bitte geben Sie im Folgenden die Daten Ihrer
                    Erstfinanzierung ein und klicken Sie dann auf „Weiter“.
                  </StyledCopy>
                  <Form
                    onSubmit={onSubmit}
                    validate={validateForm}
                    initialValues={{
                      interest: '3,9%',
                    }}
                    render={({
                      touched,
                      handleSubmit,
                      submitting,
                      active,
                      dirtySinceLastSubmit,
                    }) => (
                      <form
                        onSubmit={handleSubmit}
                        onFocus={() => {
                          tracking(1)
                        }}
                      >
                        <InputFields>
                          <InputField>
                            <Field
                              name="loan"
                              component={FinalFormInput}
                              label="Darlehensbetrag"
                              validate={composeValidators(
                                required,
                                minValue(10000)
                              )}
                              parse={onlyNumber}
                              format={formatPrice}
                              active={active}
                              dirtySinceLastSubmit={dirtySinceLastSubmit}
                              formatOnBlur
                            />
                          </InputField>
                          <InputField>
                            <Field
                              name="duration"
                              label="Dauer der Sollzinsbindung"
                              validate={required}
                              component={FinalFormInput}
                              type="select"
                              options={[
                                { value: 5, label: '5 Jahre' },
                                { value: 10, label: '10 Jahre' },
                                { value: 15, label: '15 Jahre' },
                                { value: 20, label: '20 Jahre' },
                                { value: 25, label: '25 Jahre' },
                                { value: 30, label: '30 Jahre' },
                              ]}
                              placeholder=""
                              formatOnBlur
                            />
                            <StyledMoreInfo
                              headline="Sollzinsbindung"
                              copy="Die Sollzinsbindung ist die Zeit, für die ein fester Zinssatz für Ihr Darlehen vereinbart wird. Wenn Sie - wie die meisten unserer Kunden - einen langen Planungshorizont haben, können Sie die Zinsen bis zu 20 Jahre und mehr festschreiben - und somit jegliches Zinsänderungsrisiko ausschließen. Der Vorteil für Sie: Nach deutschem Recht (§ 489 I Nr. 3 BGB) haben Sie als Kreditnehmer nach 10 Jahren grundsätzlich die Option, das Darlehen mit einer Frist von 6 Monaten teilweise oder vollständig zurück zu zahlen. Sie sind somit völlig flexibel, wenn Sie Ihr Objekt doch frühzeitig verkaufen oder nach 10 Jahren von möglicherweise günstigeren Zinsen profitieren wollen. Der Nachteil dieser attraktiven Kombination aus Sicherheit und Flexibilität ist der Preis. Denn im Regelfall gilt: Je länger die Sollzinsbindung, desto höher der Zinssatz. Und der Zinsabstand von längeren Sollzinsbindungen gegenüber dem 10-Jahres-Satz schwankt täglich und ist von zahlreichen Kapitalmarktfaktoren abhängig. Kürzere Sollzinsbindungen sind zum Teil erheblich günstiger als lange Sollzinsbindungen."
                            />
                            <OnChange name="duration">
                              {v => setInitialDuration(v.value)}
                            </OnChange>
                          </InputField>
                          <InputField>
                            <Field
                              name="estateValue"
                              component={FinalFormInput}
                              label="Immobilienwert"
                              format={formatPrice}
                              parse={onlyNumber}
                              formatOnBlur
                            />
                          </InputField>
                          <InputField>
                            <Field
                              name="rate"
                              component={FinalFormInput}
                              label={`${
                                frequency ? `${frequency.label}e` : ''
                              } Rate`}
                              validate={required}
                              format={formatPrice}
                              parse={onlyNumber}
                              formatOnBlur
                            />
                          </InputField>
                          <InputField>
                            <Field
                              name="date"
                              component={FinalFormInput}
                              type="date"
                              label="Vollauszahlungsdatum"
                              validate={required}
                              maxDate={subMonths(
                                subYears(
                                  new Date(),
                                  (initialDuration > 10
                                    ? 10
                                    : initialDuration) - 4
                                ),
                                initialDuration > 10 ? 6 : 0
                              )}
                              minDate={subYears(new Date(), initialDuration)}
                            />
                            <StyledMoreInfo
                              headline="Vollauszahlungsdatum"
                              copy="Bitte beachten Sie, dass dieses Datum als Zeitpunkt einer vollständigen Darlehensauszahlung sowie als Beginn der Sollzinsbindung unterstellt wird."
                            />
                          </InputField>
                          <InputField>
                            <Field
                              name="interest"
                              component={FinalFormInput}
                              label="Gebundener Sollzins"
                              validate={composeValidators(
                                required,
                                minValue(0.01)
                              )}
                              parse={onlyNumber}
                              format={formatPercent}
                              formatOnBlur
                            />
                            <StyledMoreInfo
                              headline="Gebundener Sollzins"
                              copy="Bitte geben Sie hier den Sollzins für Ihr Darlehen ein - dies ist der Zinssatz, mit dem Ihr Darlehen tatsächlich verzinst wird. Falls Sie Ihren Zinssatz nicht vorliegen haben, haben wir Ihnen einen durchschnittlichen Zinssatz für die Jahre zwischen 2009 und 2011 (3,9 %) zur Berechnung bereits eingetragen. Sie können dieses Feld aber gern editieren."
                            />
                          </InputField>
                          <InputField>
                            <Field
                              name="frequency"
                              component={FinalFormInput}
                              label="Häufigkeit der Ratenzahlung"
                              type="select"
                              options={[
                                { value: 12, label: 'Monatlich' },
                                { value: 4, label: 'Vierteljährlich' },
                                { value: 2, label: 'Halbjährlich' },
                                { value: 1, label: 'Jährlich' },
                              ]}
                              placeholder=""
                              validate={required}
                            />
                            <OnChange name="frequency">
                              {v => setFrequency(v)}
                            </OnChange>
                          </InputField>
                          <InputField>
                            <Field
                              name="zip"
                              component={FinalFormInput}
                              label="Postleitzahl"
                              validate={composeValidators(
                                required,
                                validateZip
                              )}
                              parse={onlyNumber}
                              formatOnBlur
                            />
                            <OnChange name="zip">{v => getCity(v)}</OnChange>
                          </InputField>
                        </InputFields>
                        <ButtonWrapper>
                          <Scrollchor ref={ref => setStep2(ref)} to="#step2" />
                          <Button
                            highlight
                            block
                            type="submit"
                            disabled={submitting}
                          >
                            Weiter
                          </Button>
                        </ButtonWrapper>
                      </form>
                    )}
                  />
                </Content>
              </StepsList>
              <StepsList
                id="step2"
                type={
                  (activePhase === 1 && 'active') ||
                  (finishedPhase.includes(1) && 'success') ||
                  'disabled'
                }
                number={2}
                headline="Anschlussfinanzierung"
                isOpened={activePhase === 1}
                onClick={() => finishedPhase.includes(1) && setActivePhase(1)}
              >
                <Content>
                  <StyledCopy>
                    Bitte geben Sie nun die Daten für Ihre Anschlussfinanzierung
                    ein. Die eingegeben Daten aus Ihrer Erstfinanzierung werden
                    automatisch übertragen.
                  </StyledCopy>
                  <Form
                    validate={validateForm}
                    onSubmit={onSecondSubmit}
                    initialValues={{
                      remainingDebt,
                      date,
                    }}
                    render={({ handleSubmit, submitting, dirty }) => (
                      <form onSubmit={handleSubmit}>
                        <InputFields>
                          <InputField>
                            <Field
                              name="remainingDebt"
                              label="Voraussichtliche Restschuld"
                              component={FinalFormInput}
                              validate={required}
                              format={formatPrice}
                              parse={onlyNumber}
                            />
                          </InputField>
                          <InputField>
                            <Field
                              name="duration"
                              label="Dauer der Sollzinsbindung"
                              validate={required}
                              component={FinalFormInput}
                              type="select"
                              options={[
                                { value: 5, label: '5 Jahre' },
                                { value: 10, label: '10 Jahre' },
                                { value: 15, label: '15 Jahre' },
                                { value: 20, label: '20 Jahre' },
                                { value: 25, label: '25 Jahre' },
                                { value: 30, label: '30 Jahre' },
                              ]}
                              placeholder=""
                              formatOnBlur
                            />
                          </InputField>
                          <InputField>
                            <Field
                              disabled
                              name="date"
                              label="Beginn der Anschlussfinanzierung"
                              type="date"
                              component={FinalFormInput}
                              validate={required}
                            />
                            <StyledMoreInfo
                              headline="Anschlussfinanzierung"
                              copy="Als Zeitpunkt der Anschlussfinanzierung haben wir das Datum Ihrer ursprünglichen Finanzierung mit der Dauer Ihrer Sollzinsbindung addiert. Sollte Ihre Sollzinsbindung mehr als 10 Jahre betragen, haben wir das Datum auf 10 Jahre nach Ihrer usrpünglichen Finanzierung festgelegt, da Sie nach deutschem Recht (§ 489 I Nr. 3 BGB) als Kreditnehmer nach 10 Jahren grundsätzlich die Option haben, das Darlehen mit einer Frist von 6 Monaten teilweise oder vollständig zurück zu zahlen. Sie sind somit völlig flexibel, wenn Sie Ihr Objekt doch frühzeitig verkaufen oder nach 10 Jahren von möglicherweise günstigeren Zinsen profitieren wollen."
                            ></StyledMoreInfo>
                          </InputField>
                          {/* <InputField>
                        <Field
                          name="extra"
                          component={FinalFormInput}
                          label="Sondertilgung"
                          format={formatPrice}
                          parse={onlyNumber}
                          formatOnBlur
                        />
                      </InputField> */}
                          {/* <InputField>
                        <Field
                          validate={composeValidators(required, minValue(0.01))}
                          name="interest"
                          component={FinalFormInput}
                          label="Gebundener Sollzins"
                          format={formatPercent}
                          parse={onlyNumber}
                          formatOnBlur
                        />
                        <StyledMoreInfo />
                      </InputField> */}
                        </InputFields>
                        <ButtonWrapper>
                          <Button
                            highlight
                            block
                            type="submit"
                            disabled={submitting}
                          >
                            <Scrollchor
                              ref={ref => setStep3(ref)}
                              to="#step3"
                            />
                            {loading ? (
                              <Spinner color="white" size="3rem" />
                            ) : (
                              'Weiter'
                            )}
                          </Button>
                        </ButtonWrapper>
                      </form>
                    )}
                  />
                </Content>
              </StepsList>
              <StepsList
                id="step3"
                type={
                  (activePhase === 2 && 'active') ||
                  (finishedPhase.includes(2) && 'success') ||
                  'disabled'
                }
                number={3}
                headline="Ergebnis"
                isOpened={activePhase === 2}
                onClick={() => finishedPhase.includes(2) && setActivePhase(2)}
              >
                <Content>
                  <StyledCopy>
                    Im Ergebnis unten sehen Sie nun Ihr Sparpotenzial im
                    Vergleich zwischen Ihren alten Konditionen und den
                    Konditionen Ihrer Anschlussfinanzierung. Durch das
                    Verschieben des Reglers können Sie selbst ausprobieren, wie
                    sich das Ergebnis verändert.
                  </StyledCopy>
                  <Sliders>
                    <Slider>
                      <H3>{frequency && frequency.label}e Rate</H3>
                      <StyledRange
                        step={1}
                        min={
                          parseFloat(minRate) > 200 ? parseFloat(minRate) : 200
                        }
                        max={9000}
                        values={minRate < rate ? [rate] : [minRate]}
                        onMouseUp={values => {
                          setRate(values[0])
                          calcNew({ rate: values[0] })
                        }}
                        suffix="€"
                        showScale
                      />
                    </Slider>
                    <Slider>
                      <H3>Gebundener Sollzins</H3>
                      <InterestOfferBox>
                        <InterestOffer>
                          {interest.toFixed(2).replace('.', ',')}% p.a.
                        </InterestOffer>
                        <Copy>
                          Der Zinssatz entspricht einem aktuellen, realen
                          Zinssatz anhand Ihrer Eingaben.
                        </Copy>
                      </InterestOfferBox>
                      {/* <StyledRange
                    step={0.01}
                    min={0.01}
                    max={4}
                    values={[interest.toFixed(2)]}
                    onChange={values => setInterest(values[0])}
                    onMouseUp={calcNew}
                    suffix="%"
                    showScale
                  /> */}
                    </Slider>
                  </Sliders>
                  <Line />
                  <StyledResults
                    debtFree={[finishDate, newFinishDate]}
                    payed={[
                      numeral(payedSum).format('0,0$'),
                      numeral(newPayedSum).format('0,0$'),
                    ]}
                    savedTime={differenceInMonths(
                      parse(finishDate, 'dd. MMMM yyyy', new Date(), {
                        locale: de,
                      }),
                      parse(newFinishDate, 'dd. MMMM yyyy', new Date(), {
                        locale: de,
                      })
                    )}
                    savedMoney={numeral(payedSum - newPayedSum).format('0,0$')}
                  />
                  <ButtonWrapper>
                    <Scrollchor to="#kundenberatung">
                      <Button highlight block type="submit">
                        {loading ? (
                          <Spinner color="white" size="1.6rem" />
                        ) : (
                          'Finanzierung anfragen'
                        )}
                      </Button>
                    </Scrollchor>
                  </ButtonWrapper>
                </Content>
              </StepsList>
            </Col>
          </Row>
        </Container>
      </Wrapper>
    </Section>
  )
}
export default Tilgungsrechner
