import React from 'react'
import styled from 'styled-components'
import Geocoder from 'react-map-gl-geocoder'
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { space } from 'styled-system'
import SectionHeadline from '../styled/sectionHeadline'
import { H3, Copy } from '../Text'
import { h3, h1 } from '../../styles/text'
import media from '../../styles/media'

import searchImageUrl from '../../images/search.svg'

const StyledContainer = styled(Container)`
  ${space};
  text-align: center;
`

const StyledImage = styled.div`
  ${space};
  display: none;
  ${media.up.sm`
    display: block;
  `}
`

const InputWrapper = styled.div`
  ${space};
  text-align: left;
  .mapboxgl-ctrl-geocoder--icon-search {
    display: none;
  }
`

const ImageTextWrapper = styled.div`
  ${space};
  display: flex;
  align-items: center;
  justify-content: center;
  ${media.up.sm`
    flex-direction: row;
    `}
  ${media.up.sm`
    flex-direction: column;
  `}
`

const Text = styled(Copy)`
  ${space};
`

const Headline = styled.h1`
  ${space}
  ${h1}
`

export default class MapSearch extends React.Component {
  state = {
    componentDidMount: false,
  }

  searchRef = React.createRef()

  componentDidMount() {
    // console.log('did mount')
    this.setState({ componentDidMount: true })
  }

  componentWillUnmount() {
    // console.log('componentWillUnmount')
  }

  render() {
    const { mapRef, onViewportChange } = this.props
    const { componentDidMount } = this.state
    // console.log('mapRef in search', mapRef)
    return (
      <StyledContainer px={4}>
        <Row>
          <Col>
            <Headline mt={6}>Jetzt Sparpotenzial entdecken!</Headline>
            <ImageTextWrapper>
              <StyledImage mt={6}>
                <img src={searchImageUrl} />
              </StyledImage>
              <Text mt={{ xxs: 2, md: 5 }}>
                Finden Sie jetzt heraus, wie hoch das Sparpotenzial in Ihrer
                Region ist.
              </Text>
            </ImageTextWrapper>
            <InputWrapper mt={4} mb={5} ref={this.searchRef}>
              {mapRef && mapRef.current && componentDidMount && (
                <Geocoder
                  placeholder="Ihre Adresse"
                  mapRef={mapRef}
                  // onResult={p => console.log(p)}
                  containerRef={this.searchRef}
                  onViewportChange={onViewportChange}
                  mapboxApiAccessToken="pk.eyJ1IjoiY3B0Y29tcGlsZXIiLCJhIjoiY2pydXRzNDUwMGNxdzQ3bm1hZjJhejM4aSJ9.w9lv0mKtJQFjuyou42q2tw"
                  position="top-left"
                  countries="de"
                  language="de"
                />
              )}
            </InputWrapper>
          </Col>
        </Row>
      </StyledContainer>
    )
  }
}
