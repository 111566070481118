import React from 'react'

export const ArrowDown = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24px"
    height="15px"
  >
    <defs>
      <path
        id="a"
        d="M28 23.8786797L30.1213203 26l9.1923882 9.1923882-2.1213203 2.1213203-9.1920967-9.1920288-9.1926797 9.1920288-2.1213203-2.1213203L28 23.8786797z"
      />
    </defs>
    <use
      fill={color}
      fillRule="evenodd"
      transform="rotate(-180 20 19.096194)"
      xlinkHref="#a"
    />
  </svg>
)
