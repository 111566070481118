import { addMonths, subMonths } from 'date-fns'

export const percentageToColor = (percentage, maxHue = 120, minHue = 0) => {
  const hue = percentage * (maxHue - minHue) + minHue
  return `hsl(${hue}, 100%, 50%)`
}

export const formatEuro = v =>
  typeof v === 'string'
    ? parseFloat(
        v
          .replace('€', '')
          .replace(/\./g, '')
          .replace(/,/g, '.')
      )
    : v

export const formatPercentage = v =>
  typeof v === 'string'
    ? parseFloat(
        v
          .replace('%', '')
          .replace(/\./g, '')
          .replace(/,/g, '.') / 100
      )
    : v / 100
export const calcMinRate = (loan, interest, frequency, rate) => {
  const formattedLoan = loan && formatEuro(loan)
  const formattedInterest = interest && formatPercentage(interest)
  const min =
    (formattedLoan * formattedInterest) / (frequency && frequency.value) +
    (formattedLoan * 0.01) / (frequency && frequency.value)
  return min
}

export const getInterest = (
  zip,
  marketValue,
  date,
  loan,
  maturity,
  amortisation
) => {
  const data = {
    caseDto: {
      estate: {
        zip,
      },
      mainApplicant: {
        zip: '',
        city: '',
        federalState: '',
        netSalary: 0,
      },
      venture: {
        reason: 'Umschuld',
        percentageBroker: 3.5,
        marketValue,
        shownFunding: {
          additionalPriorityLoan: 0,
          payoutDate: date.toISOString(),
          loans: [
            {
              amount: loan,
              maturity,
              amortisation,
            },
          ],
        },
        brokerCosts: 0,
        notaryCosts: 0,
        transferTax: 0,
      },
      handling: {},
      calledBy: 'zinscheck18',
    },
    numberOfResults: 1,
  }
  return new Promise((resolve, reject) => {
    fetch(
      'https://www.interhyp.de/customer-portal/api/interestcalculator/v1/marketOverview',
      {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, cors, *same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrer: 'no-referrer', // no-referrer, *client
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      }
    )
      .then(response => response.json())
      .then(res => resolve(res))
      .catch(err => reject(err))
  })
}
export const getCity = val =>
  new Promise(resolve => {
    if (val.length === 5) {
      fetch(
        `https://www.interhyp.de/customer-portal/api/interestcalculator/v1/legalAndTaxCostsAndPlaceForPostcode/${val}`,
        {
          method: 'GET',
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'same-origin',
          redirect: 'follow',
          referrer: 'no-referrer',
        }
      ).then(response => response.json().then(res => resolve(res)))
    }
  })
export const calcInterestPayment = (
  loan,
  rate,
  payback,
  period,
  startDate,
  years,
  repayment
) => {
  console.log({ loan, rate, payback, period, startDate, years, repayment })
  let rest = loan
  let restAfterPeriod
  let debtSumAfterPeriod
  let ratePerinterval
  let date
  let debtSum = 0

  const payments = years * period

  let i = 0
  while (rest > 0) {
    i += 1
    let annui
    switch (period) {
      case 12:
        date = addMonths(startDate, i + 1)
        annui = payback - (rest * rate) / period
        ratePerinterval = (rest * rate) / period

        break
      case 4:
        date = addMonths(startDate, i * 3)
        annui = payback - (rest * rate) / period
        ratePerinterval = (rest * rate) / period
        break
      case 2:
        date = addMonths(startDate, i * 6)
        annui = payback - (rest * rate) / period
        ratePerinterval = (rest * rate) / period
        break
      case 1:
        date = addMonths(startDate, i * 12)
        annui = payback - rest * rate
        ratePerinterval = rest * rate
        break

      default:
        break
    }
    debtSum += ratePerinterval
    rest -= annui
    if (!restAfterPeriod) {
      restAfterPeriod = i === years * period ? rest : null
    }
    if (!debtSumAfterPeriod) {
      debtSumAfterPeriod =
        i === years * period ? debtSum + (loan - restAfterPeriod) : null
    }
  }
  date = subMonths(date, 1)
  debtSum += loan
  return { rest, restAfterPeriod, debtSumAfterPeriod, date, debtSum }
  // rest = loan
  // console.table(
  //   Array.from({ length: payments }).map((y, i) => {
  //     let annui
  //     let ratePerinterval
  //     switch (period) {
  //       case 12:
  //         date = addMonths(startDate, i + 1)
  //         annui = payback - (rest * rate) / 12
  //         ratePerinterval = (rest * rate) / 12
  //         break
  //       case 4:
  //         date = addMonths(startDate, i * 3)
  //         annui = payback - (rest * rate) / 4
  //         ratePerinterval = (rest * rate) / 4
  //         break
  //       case 2:
  //         date = addMonths(startDate, i * 6)
  //         annui = payback - (rest * rate) / 2
  //         ratePerinterval = (rest * rate) / 2
  //         break
  //       case 1:
  //         date = addMonths(startDate, i * 12)
  //         annui = payback - rest * rate
  //         ratePerinterval = rest * rate
  //         break

  //       default:
  //         break
  //     }
  //     rest -= annui
  //     return { year: date, rest, annui, rate: ratePerinterval }
  //   })
  // )
  // return { rest, date }
}
