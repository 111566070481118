import React from 'react'
import styled from 'styled-components'
import { space } from 'styled-system'
import { Copy } from '../Text'
import media from '../../styles/media'
import Prev from '../../images/arrowleft.svg'
import Next from '../../images/arrowright.svg'

let Flickity = null
const Spacer = styled.div`
  ${space}
`

const NavButton = styled.img`
  display: none;
  min-width: 7.2rem;
  cursor: pointer;
  top: 50%;
  position: absolute;
  &:first-of-type {
    left: 0;
    transform: translate(-50%, -50%);
  }
  &:last-of-type {
    right: 0;
    transform: translate(50%, -50%);
  }
  ${media.up.sm`
    display: block
  `}
`
const CarouselItem = styled.div`
  width: 100%;
  margin-right: 4rem;
`

const SliderContent = styled.div`
  overflow: hidden;
`

const Dots = styled.div`
  margin-top: 3.2rem;
  display: flex;
  justify-content: center;
  ${media.down.sm`
    margin-top: 2.4rem;
  `}
`

const Dot = styled.div`
  cursor: pointer;
  border-radius: 50%;
  border: 0.2rem solid black;
  width: 2.4rem;
  height: 2.4rem;
  background-color: ${props => (props.active ? 'black' : 'transparent')};
  &:not(:last-child) {
    margin-right: 1.6rem;
  }
  ${media.down.sm`
    width: 1.6rem;
    height: 1.6rem;
  `}
`

const Wrapper = styled.div`
  justify-content: center;
  flex-direction: column;
  align-items: center;

  position: relative;
`

class Slider extends React.Component {
  constructor(props) {
    super(props)
    this.flkty = React.createRef()
    this.state = {
      selectedSlide: 0,
      didMount: false,
    }
  }

  async componentDidMount() {
    const module = await import('react-flickity-component')
    Flickity = module.default
    this.setState(prev => ({ ...prev, didMount: true }))
    this.flkty.on('change', () => {
      // console.log(`current index is ${this.flkty.selectedIndex}`)
      this.setState(prev => ({
        ...prev,
        selectedSlide: this.flkty.selectedIndex,
      }))
    })
  }

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   this.flkty.on('change', () => {
  //     this.setState(prev => ({
  //       ...prev,
  //       selectedSlide: this.flkty.selectedIndex,
  //     }))
  //   })
  // }

  prev() {
    this.flkty.previous()
  }

  next() {
    this.flkty.next()
  }

  gotoSlide(index) {
    this.flkty.select(index)
  }

  render() {
    const { selectedSlide } = this.state

    return (
      <Spacer>
        <Wrapper>
          <SliderContent>
            {Flickity ? (
              <Flickity
                flickityRef={c => (this.flkty = c)}
                options={{
                  prevNextButtons: false,
                  pageDots: false,
                  adaptiveHeight: true,
                  wrapAround: true,
                }}
                static
              >
                {React.Children.map(this.props.children, (item, index) => (
                  <CarouselItem key={index}>{item}</CarouselItem>
                ))}
              </Flickity>
            ) : (
              <>{this.props.children}</>
            )}
          </SliderContent>
          <NavButton src={Prev} onClick={() => this.prev()} />
          <NavButton src={Next} onClick={() => this.next()} />
        </Wrapper>
        <Dots>
          {this.props.children.length ? (
            React.Children.map(this.props.children, (item, index) => (
              <Dot
                key={index}
                onClick={() => this.gotoSlide(index)}
                active={index === selectedSlide}
              />
            ))
          ) : (
            <Copy as="div">
              {this.state.selectedSlide + 1} von {this.props.children.length}
            </Copy>
          )}
        </Dots>
      </Spacer>
    )
  }
}

export default Slider
