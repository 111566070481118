import React, { useState } from 'react'
import styled, { withTheme, css } from 'styled-components'
import { Collapse } from 'react-collapse'
import { space } from 'styled-system'
import Scrollchor from 'react-scrollchor'
import { H2, H3, Copy } from '../Text'
import Button from '../Buttons'
import media from '../../styles/media'

import closeImageUrl from '../../images/close2.svg'
import { ArrowDown } from '../Icons'
import MoreInfo from '../MoreInfo'

const StyledContainer = styled.div`
  ${space};
`

const Header = styled.div`
  ${space};
  padding: 0 1.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 9.8rem;
  hyphens: auto;
  background-color: ${props => props.theme.colors.background};
  ${media.down.sm`
    height: 9.2rem;
  `}
`

const StyledCloseButton = styled.img`
  cursor: pointer;
  margin-left: 4.8rem;
`

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`

const CollapseHeader = styled.div`
  cursor: pointer;
  background-color: ${props => props.theme.colors.background};
  border-top: 1px solid #dddddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5.6rem;
  padding: 0 1.6rem;
  ${H3} {
    color: ${props =>
      props.isOpen === props.number ? props.theme.colors.primary : '#333333'};
  }
`

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  &:not(:first-child) {
    margin-top: 0.4rem;
  }
`

const CollapseBody = styled.div`
  padding: 1.5rem 1.5rem 0;
  border-top: 1px solid #dddddd;
  height: 43rem;
  ${media.down.lg`
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    height: auto;
  `}
  ${media.down.md`
    display: block;
  `}
`

const InfoCopy = styled(Copy)`
  color: #333333;
`

const Section = styled.div`
  padding-bottom: 1.6rem;
  box-sizing: content-box;
  &:not(:first-child) {
    padding-top: 1.6rem;
    border-top: 1px solid #dddddd;
  }
  ${media.down.lg`
    flex-basis: calc(50% - 1.5rem);
    min-height: 9.9rem;
    &:not(:first-child) {
      border-top: 0;
      padding-top: 0;
    }
    &:not(:first-child):not(:nth-child(2)){
      padding-top: 1.6rem;
      border-top: 1px solid #dddddd;
    }
    &:not(:nth-child(2n)){
      margin-right: 1.5rem;
    }
    &:nth-child(2n) {
      margin-left: 1.5rem;
    }
  `}
  ${media.down.md`
      padding-bottom: 1.6rem;
  &:not(:first-child) {
    padding-top: 1.6rem;
    border-top: 1px solid #dddddd;
  }
  flex-basis: 100%;
  min-height: auto;
    &:not(:nth-child(2n)){
      margin-right: 0;
    }
    &:nth-child(2n) {
      margin-left: 0;
    }
  `}
`

const ArrowWrapper = styled.div`
  transition: transform 0.3s ease-out;
  ${props =>
    props.isOpen === props.number &&
    css`
      transform: rotate(180deg);
    `};
`

const ButtonWrapper = styled.div`
  margin-top: auto;
  width: 100%;
`

const Info = ({ headline, info }) => (
  <InfoWrapper>
    <InfoCopy>Ø {headline}</InfoCopy>
    <H3>{info}</H3>
  </InfoWrapper>
)

const MapDetails = ({ onClose, data, theme }) => {
  if (!data.Darlehenssumme) {
    return <div />
  }
  const [isOpen, setOpen] = useState(0)

  const calcTimeSaving = months => {
    months = months.toFixed()
    const years = Math.floor(months / 12)
    const restMonths = months % 12

    // console.log('months', months)
    // console.log('years', years)

    return `${years} Jahr${years > 1 ? 'e' : ''}${
      restMonths > 0 ? `, ${restMonths} Monat${restMonths > 1 ? 'e' : ''}` : ''
    }`
  }

  return (
    <StyledWrapper>
      <StyledContainer>
        <Header>
          <H2 as="h2">
            <strong>{data.Kreis}</strong>
          </H2>
          <StyledCloseButton
            src={closeImageUrl}
            alt="schließen"
            onClick={() => onClose()}
          />
        </Header>
      </StyledContainer>
      <CollapseHeader number={0} onClick={() => setOpen(0)} isOpen={isOpen}>
        <div style={{ display: 'flex' }}>
          <H3>Sparpotenzial</H3>
          <div style={{ marginLeft: '2rem' }}>
            <MoreInfo
              headline="Vergleich zweier Laufzeiten"
              copy={
                <div>
                  <ol>
                    <li>
                      Laufzeit bis zur vollständigen Schuldenfreiheit, die bei
                      Abschluss des Darlehens im Jahr 2009 bis 2011 aufgrund der
                      damaligen Zinssätze und monatlichen Rate zu erwarten war.
                    </li>
                    <li>
                      Gesamtlaufzeit bis zur vollständigen Schuldenfreiheit,
                      wenn die Darlehensnehmer nach zehn Jahren zu einem
                      günstigen Anschlusskredit wechseln und der günstige Zins
                      und die Rate bis zum Ende der verbleibenden Laufzeit
                      gleich bleiben.
                    </li>
                  </ol>
                  Zeitliche Differenz: Sparpotenzial in Monaten. Differenz der
                  Zinskosten: finanzielles Sparpotenzial in Euro
                </div>
              }
            />
          </div>
        </div>
        <ArrowWrapper isOpen={isOpen} number={0}>
          <ArrowDown color={isOpen === 0 ? theme.colors.primary : '#333333'} />
        </ArrowWrapper>
      </CollapseHeader>
      <Collapse isOpened={isOpen === 0}>
        <CollapseBody>
          <Section>
            <Info
              headline="Einsparpotenzial in Jahren und Monaten"
              info={`${calcTimeSaving(data.Zeitersparnis_Monate)}`}
            />
          </Section>
          <Section>
            <Info
              headline="Einsparpotenzial in € je Objekt"
              info={`${parseFloat(data.Geldersparnis.toFixed(2)).toLocaleString(
                'de-DE'
              )} €`}
            />
          </Section>
          <Section>
            <Info
              headline="Nominalzins bei der Erstfinanzierung ('09-'11)"
              info={`${parseFloat(
                data.Nominalzins_gew.toFixed(2)
              ).toLocaleString('de-DE')} %`}
            />
          </Section>
          <Section>
            <Info
              headline="Nominalzins von Anschlussfinanzierung im Kreis ('17-'19)"
              info={`${parseFloat(
                data.Nominalzins_gew_Kreis.toFixed(2)
              ).toLocaleString('de-DE')} %`}
            />
          </Section>
        </CollapseBody>
      </Collapse>
      <CollapseHeader number={1} onClick={() => setOpen(1)} isOpen={isOpen}>
        <H3>Kerninfos zum Kreis</H3>
        <ArrowWrapper isOpen={isOpen} number={1}>
          <ArrowDown color={isOpen === 1 ? theme.colors.primary : '#333333'} />
        </ArrowWrapper>
      </CollapseHeader>
      <Collapse isOpened={isOpen === 1}>
        <CollapseBody>
          <Section>
            <Info
              headline="Kaufpreis je Objekt"
              info={`${parseFloat(
                data.Kaufpreis_Objekt.toFixed(2)
              ).toLocaleString('de-DE')} €`}
            />
          </Section>
          <Section>
            <Info
              headline="Darlehenssumme je Objekt"
              info={`${parseFloat(
                data.Darlehenssumme.toFixed(2)
              ).toLocaleString('de-DE')} €`}
            />
          </Section>
          <Section>
            <Info
              headline="Restschuld je Objekt (Stichtag 04.02.2019)"
              info={`${parseFloat(data.Restschuld.toFixed(2)).toLocaleString(
                'de-DE'
              )} €`}
            />
          </Section>
          <Section>
            <Info
              headline="Monatliche Rate je Objekt"
              info={`${parseFloat(
                data.Monatliche_Rate.toFixed(2)
              ).toLocaleString('de-DE')} €`}
            />
          </Section>
        </CollapseBody>
      </Collapse>
      <ButtonWrapper>
        <Scrollchor to="#tilgungsrechner">
          <Button block>Jetzt Finanzierungsplan berechnen</Button>
        </Scrollchor>
      </ButtonWrapper>
    </StyledWrapper>
  )
}

export default withTheme(MapDetails)
