import styled from 'styled-components'
import { space } from 'styled-system'
import media from '../../styles/media'
import { small } from '../../styles/text'

const SectionHeadline = styled.div`
  ${small}
  ${space}

  text-transform: uppercase;
  color: white;
  display: inline-block;
  background: #989890;
  box-shadow: 4px 4px 1px 0px #dddddd;
  padding: 0.6rem 1.1rem;
`

export default SectionHeadline
