import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { space } from 'styled-system'
import media from '../../styles/media'
import { Copy } from '../Text'
import Section from '../Section'
import Button from '../Buttons'
import Beratung from '../../images/kundenberatung.svg'

const Spacer = styled.div`
  ${space}
`

const Center = styled.div`
  text-align: center;
`

const Consulting = () => {
  const regEx = /\?(.*)/g
  const queryString =
    typeof window !== 'undefined' ? window.location.search : ''
  // console.log(
  //   queryString,
  //   typeof window !== 'undefined' ? window.location.search : 'undefined',
  //   typeof window !== 'undefined'
  // )
  return (
    <Section
      section="kundenberatung"
      headline="Lassen Sie sich persönlich beraten"
      copy="Wir ermitteln gemeinsam mit Ihnen Ihr finanzielles Optimierungspotenzial, informieren Sie über die besten Zinsbedingungen und zeigen Ihnen, wo Sie sparen können. Dabei beraten wir Sie ganz persönlich und individuell, um das Beste für Sie und Ihr Finanzierungsprojekt herauszuholen."
      noPadding
      image={Beratung}
    >
      <Container>
        <Row>
          <Col col={12}>
            <Center>
              {typeof window !== 'undefined' && (
                <Button
                  highlight
                  mt={{ xxs: 4 }}
                  href={`https://www.interhyp.de/beratungstermin-vereinbaren-ab/${queryString ||
                    ''}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() =>
                    utag &&
                    utag.link({
                      event_action: 'button_click',
                      event_linktype: 'internal',
                      event_source: 'schuldenfrei_tag_interhyp',
                      event_target: '/beratungstermin-vereinbaren-ab/',
                      event_title: 'Jetzt Beratungstermin vereinbaren',
                    })
                  }
                >
                  Jetzt Beratungstermin vereinbaren
                </Button>
              )}
            </Center>
            <Spacer pb={{ xxs: 5, md: 10 }} />
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

export default Consulting
