import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { space } from 'styled-system'
import media from '../../styles/media'
import { Copy, H3 } from '../Text'
import Section from '../Section'
import Checklist from '../Checklist'
import Slider from '../Slider'

const Box = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 52rem;
  cursor: grab;
  background: #f7f6f4;
  margin: 0 4px 4px 0;
  padding: 2.2rem 1.6rem;
  ${media.up.sm`

  padding: 3.2rem 6.4rem;
  `}
  &:active {
    cursor: grabbing;
  }

  ${media.down.xl`
    height: 31rem;
  `}
  ${media.down.lg`
    height: 39rem;
  `}
  ${media.down.md`
    height: 51rem;
  `}
  ${media.down.sm`
    height: 52rem;
  `}
`

const questions = [
  {
    q: 'Was ist die Anschlussfinanzierung?',
    a:
      'Diese benötigen Sie, wenn die Zinsbindung Ihrer bisherigen Finanzierung ausläuft, das Darlehen aber noch nicht vollständig zurückgezahlt ist. Das ist fast in allen Fällen so, außer Sie haben ein sog. Volltilgerdarlehen abgeschlossen. Sie haben zwei Möglichkeiten: Führen Sie Ihre Finanzierung bei Ihrer bisherigen Hausbank fort oder wechseln Sie, z.B. wegen besserer Konditionen, den Anbieter (Stichwort Umschuldung).',
  },
  {
    q: 'Was ist der richtige Zeitpunkt für Ihre Anschlussfinanzierung?',
    a:
      'Je früher Sie sich damit beschäftigen, desto besser. Holen Sie zeitnah mehrere Angebote ein und vergleichen Sie diese in Ruhe miteinander, um die beste Option zu finden. In jedem Fall legt Ihre Hausbank Ihnen spätestens drei Monate vor dem Ende Ihres bisherigen Darlehens ein Angebot vor – spätestens dann sollten Sie sich entscheiden.',
  },
  {
    q: 'Wie sind die Fristen für eine Anschlussfinanzierung?',
    a:
      'Die Fristen hängen von Ihrer Zinsbindung ab: Wenn diese unter 10 Jahren liegt, können Sie bereits fünfeinhalb Jahre vor Laufzeitende ein Forward-Darlehen beantragen. Ein Jahr vor Ende haben Sie die Möglichkeit, die Anschlussfinanzierung zu aktuellen Konditionen festzumachen. Und drei Monate vor dem Ende unterbreitet Ihnen Ihre Bank ein neues Angebot – spätestens dann sollten Sie sich entschieden haben, wie Ihre Anschlussfinanzierung aussehen soll. Wenn Ihre Zinsbindung mehr als 10 Jahre beträgt, können Sie Ihre laufende Finanzierung 10 Jahre nach Vollauszahlung mit einer Frist von 6 Monaten jederzeit kündigen.',
  },
  {
    q: 'Ab wann lohnt sich die Umschuldung?',
    a:
      'Für die Anschlussfinanzierung und die Ablösung Ihrer Restschulden gibt es die Möglichkeit der Umschuldung: Hierbei löst ein neuer Kreditgeber Ihren bisherigen ab. Das lohnt sich gerade dann, wenn Ihre Restschulden relativ hoch sind und die neuen Konditionen signifikant besser – vergleichen lohnt sich. Ihre Grundschulden gehen als Kreditsicherheit an Ihren neuen Kreditgeber über.',
  },
  {
    q: 'Was kostet ein Anbieterwechsel (Stichwort Umschuldung)?',
    a:
      'Sie können sich jederzeit kostenlos Angebote von anderen Banken einholen und diese miteinander vergleichen. Für die Umschuldung fallen in der Regel nur die Grundschuldabtretung, Notar- und Grundbuchgebühren an – diese liegen meist im Bereich von einigen hundert Euro.',
  },
  {
    q: 'Was ist ein Forward-Darlehen?',
    a:
      'Eine Möglichkeit der Anschlussfinanzierung, bei der Sie bereits bis zu 5,5 Jahre vor Ende der Laufzeit die Konditionen festlegen können. Das gibt Ihnen die Möglichkeit, auch später von günstigen Zinssätzen zu profitieren und somit schneller schuldenfrei zu werden. Das Forward-Darlehen löst dann Ihre bisherige Finanzierung ab.',
  },
  {
    q: 'Was ist eine Prolongation?',
    a:
      'Bei einer Prolongation akzeptieren Sie das Angebot Ihrer Hausbank zur weiteren Finanzierung. Ihr Darlehen läuft lediglich mit neuen Konditionen weiter, die Sie spätestens drei Monate vor Ende der Laufzeit bekommen.',
  },
  {
    q:
      'Wie kann ich die verschiedenen Möglichkeiten der Anschlussfinanzierung vergleichen?',
    a:
      'Am besten lassen Sie sich vom Fachmann beraten. Wenn Sie sich selbst ein Bild machen wollen, können Sie im Interhyp Angebotsvergleichsrechner zwei verschiedene Angebote miteinander vergleichen. Dafür müssen der Darlehensbetrag und die Zinsbindungsfrist der jeweiligen Angebote identisch sein.',
  },
]

const FAQ = () => (
  <Section
    background={props => props.theme.colors.secondary}
    section="FAQ"
    headline="Fragen und Antworten zur Anschlussfinanzierung"
  >
    <Container>
      <Slider>
        {questions.map((question, key) => (
          <Box key={key}>
            <H3>{question.q}</H3>
            <Copy as="div" mt={{ xxs: 2, md: 4 }}>
              {question.a}
            </Copy>
          </Box>
        ))}
      </Slider>
    </Container>
  </Section>
)

export default FAQ
