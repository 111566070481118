import React, { useState } from 'react'
import styled from 'styled-components'
import { Copy, H3 } from '../Text'
import closeIcon from '../../images/close2.svg'
import media from '../../styles/media'

const Wrapper = styled.div`
  cursor: pointer;
  border: 1px solid #cccccc;
  border-radius: 100%;
  width: 3.2rem;
  height: 3.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
`

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`

const Content = styled.div`
  z-index: 9999;
  display: inline-block;
  color: #333333;
  background-color: white;
  max-width: 75.9rem;
  max-height: 100vh;
  overflow-y: auto;
`

const Close = styled.img`
  cursor: pointer;
  width: 2.244rem;
  height: 2.263rem;
`

const Head = styled.div`
  min-height: 5.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.8rem;
  font-weight: bold;
  background-color: #f7f6f4;
  padding: 0 1.6rem;
`

const StyledCopy = styled(Copy)`
  font-size: 1.6rem;
  padding: 1.6rem 1.6rem 3.2rem;
  ${media.down.md`
    font-size: 1.4rem;
  `}
`

const Bg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
`

const MoreInfo = ({ className, headline, copy }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <Wrapper className={className} onClick={() => setIsOpen(true)}>
        ?
      </Wrapper>
      {isOpen && (
        <Modal>
          <Content>
            <Head>
              <H3>{headline}</H3>
              <Close src={closeIcon} onClick={() => setIsOpen(false)} />
            </Head>
            <StyledCopy as="div">{copy}</StyledCopy>
          </Content>
          <Bg onClick={() => setIsOpen(false)} />
        </Modal>
      )}
    </>
  )
}
export default MoreInfo
