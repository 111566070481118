import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { space } from 'styled-system'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import media from '../../styles/media'
import SectionHeadline from '../styled/sectionHeadline'
import { H1, Copy } from '../Text'

const StyledDiv = styled.div`
  text-align: center;
  width: 100%;
  padding-top: 4rem;
  ${media.up.md`
    padding-top: 7.4rem;
  `}
  ${space}
`

const Spacer = styled.div`
  ${space}
`

const Content = styled.div`
  text-align: initial;
`

const Section = ({ section, children, headline, copy, noPadding, image }) => (
  <StyledDiv>
    <Container>
      <Row>
        <Col>
          {headline && <H1 as="div">{headline}</H1>}
          {(copy || image) && <Spacer mt={{ xxs: 4, md: 6 }} />}
          {image && <img src={image} />}
          {image && <Spacer mt={{ xxs: 2 }} />}
        </Col>
      </Row>
      <Row>
        <Col lg={8} lgOffset={2}>
          {copy && (
            <>
              {image && <Spacer mt={{ xxs: 2 }} />}
              <Copy as="div">{copy}</Copy>
            </>
          )}
        </Col>
      </Row>
    </Container>
    {!noPadding && (headline || copy) && <Spacer pb={{ xxs: 3, md: 6 }} />}
    <Content>{children}</Content>
    {!noPadding && <Spacer pb={{ xxs: 5, md: 10 }} />}
    {/* {console.log(Section.counter)} */}
  </StyledDiv>
)

export default Section
