import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { space } from 'styled-system'
import media from '../../styles/media'
import { Copy } from '../Text'
import Section from '../Section'
import Checklist from '../Checklist'

const Image = styled.img`
  background-color: red;
  width: 100%;
  height: 33.4rem;
`

const Spacer = styled.div`
  ${space}
`
const items = [
  'schneller schuldenfrei',
  'enormes Sparpotenzial',
  'freies Budget für neue Projekte',
]

const About = () => (
  <Section
    background={props => props.theme.colors.secondary}
    section="Anschlussfinanzierung"
    headline="Mit einer Anschlussfinanzierung sparen Sie Zeit und Geld"
  >
    <Container>
      <Row>
        <Col col={12} lg={6}>
          <Copy>
            Bei vielen Immobilienkrediten l&auml;uft die Zinsbindungsfrist nach
            5, 10 oder 15 Jahren aus. Die Restschuld ist bis zu diesem Zeitpunkt
            jedoch nur selten getilgt. Mit der richtigen Anschlussfinanzierung
            regeln Sie die R&uuml;ckzahlung der verbleibenden Schulden zu Ihrem
            Vorteil: Wenn Sie sich fr&uuml;hzeitig darum k&uuml;mmern, haben Sie
            gute Chancen, Ihre R&uuml;ckzahlungsbedingungen drastisch zu
            verbessern.
            <br />
            <br />
            Obwohl Ihnen Ihre aktuelle Bank unter Umst&auml;nden ein
            g&uuml;nstigeres Angebot als bei der Erstfinanzierung vorlegen wird
            (Prolongation), besteht aber durchaus die M&ouml;glichkeit, Ihr
            Sparpotenzial nochmal deutlich zu heben: Denn neben einem
            verbesserten Zinsumfeld, hat sich vermutliche Ihre private oder
            finanzielle Situation in den letzten Jahren ver&auml;ndert. Mit der
            richtigen Anschlussfinanzierung k&ouml;nnen Sie Ihren Kredit an die
            aktuellen Bedingungen anpassen. Sie k&ouml;nnen beispielsweise die
            H&ouml;he der Raten &auml;ndern oder auch Sondertilgungen vornehmen.
            <br />
            <br />
            Wir wollen Sie dabei unterst&uuml;tzen, die optimale Strategie zu
            entwickeln, damit Sie noch schneller Ihren ganz pers&ouml;nlichen
            Schuldenfrei-Tag feiern k&ouml;nnen.
            <br />
          </Copy>
        </Col>
        <Col col={12} lg={5} lgOffset={1}>
          <Spacer mt={{ xxs: 6, lg: 0 }} />
          <Checklist headline="Ihre Vorteile auf einen Blick" items={items} />
        </Col>
      </Row>
    </Container>
  </Section>
)

export default About
