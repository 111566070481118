import styled from 'styled-components'
import media from '../../styles/media'

const Box = styled.div`
  background: #ffffff;
  padding: 1.6rem 1.6rem 1.6rem 1.6rem;
  ${media.up.sm`
  padding: 3.2rem 3.2rem 3.2rem 3.2rem;
  
  `}
`

export default Box
