import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { H3, Copy } from '../Text'
import media from '../../styles/media'
import checkmark from '../../images/checkmark.svg'
import Box from '../styled/box'

const StyledList = styled.ul`
  font-size: 2rem;
  line-height: 3.2rem;
  list-style-type: none;
  text-indent: 0;
  margin: 0;
  padding: 0;
  color: #222222;
  letter-spacing: 0;
  line-height: 3.2rem;
  margin-top: 1.6rem;
  ${media.up.sm`
    margin-top: 3.2rem;
    font-size: 2.4rem;
  
  `}
  li {
    display: flex;
    align-items: center;
    &:before {
      flex-shrink: 0;
      display: block;
      margin-right: 1.7rem;
      content: '';
      width: 4rem;
      height: 4rem;
      background-image: url(${checkmark});
      background-size: 100% 100%;
      ${media.up.sm`
        width: 56px;
        height: 56px;
      `}
    }
  }
  li + li {
    padding-top: 1.9rem;
    ${media.up.sm`
    padding-top: 3.2rem;
    `}
  }
`

const Checklist = ({ headline, items }) => (
  <Box>
    <H3>{headline}</H3>
    <StyledList>
      {items.map((item, index) => (
        <li key={index}>
          <Copy>{item}</Copy>
        </li>
      ))}
    </StyledList>
  </Box>
)

Checklist.propTypes = {
  headline: PropTypes.string,
  items: PropTypes.array,
}

export default Checklist
