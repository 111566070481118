import React from 'react'
import styled from 'styled-components'
import { Slider, Handles, Tracks, Rail } from 'react-compound-slider'
import thumbIcon from '../../images/sliderhandle.svg'
import theme from '../../styles/theme'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-top: 7.5rem;
`

const Scale = styled.div`
  margin-top: 1.9rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const Output = styled.div`
  pointer-events: none;
  font-weight: bold;
  position: absolute;
  top: -5.5rem;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: white;
  box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #dddddd;
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  height: 4rem;
  align-items: center;
  padding: 0 1.6rem;
  &:after,
  &:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after {
    border-top-color: white;
    border-width: 1.6rem;
    margin-left: -1.6rem;
  }
  &:before {
    border-top-color: #dddddd;
    border-width: 1.7rem;
    margin-left: -1.7rem;
  }
`

const Thumb = styled.div.attrs({
  style: ({ percent }) => ({
    left: `${percent}%`,
  }),
})`
  position: absolute;
  height: 5.6rem;
  width: 5.6rem;
  background: url(${thumbIcon});
  background-repeat: no-repeat;
  margin-left: -2.8rem;
  margin-top: -3.5rem;
  z-index: 2;
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
`

const RailStyle = styled.div`
  height: 1.6rem;
  width: 100%;
  box-shadow: inset 0 1px 3px 0 rgba(51, 51, 51, 0.3);
  border-radius: 8px;
`

const TrackStyle = styled.div.attrs({
  style: ({ source, target }) => ({
    left: `${source.percent}`,
    width: `${target.percent - source.percent}%`,
  }),
})`
  position: absolute;
  height: 1.6rem;
  top: 0;
  background-color: ${props => props.theme.colors.primary};
  width: 100%;
  box-shadow: inset 0 1px 3px 0 rgba(51, 51, 51, 0.3);
  border-radius: 8px;
  z-index: 1;
`

const sliderStyle = {
  position: 'relative',
  width: '100%',
}

const Handle = ({ handle: { id, value, percent }, getHandleProps, suffix }) => (
  <Thumb percent={percent} {...getHandleProps(id)}>
    <Output>
      {value.toFixed(2).replace('.', ',')}
      {suffix && suffix}
    </Output>
  </Thumb>
)

const Track = ({ source, target, getTrackProps }) => (
  <TrackStyle source={source} target={target} {...getTrackProps()} />
)
class RangeClass extends React.Component {
  render() {
    const {
      step,
      min,
      max,
      suffix,
      showScale,
      className,
      onChange,
      values,
      onMouseUp,
    } = this.props
    // console.log(this.props)
    return (
      <Wrapper className={className}>
        <Slider
          rootStyle={sliderStyle}
          step={step}
          domain={[min, max]}
          values={values}
          onChange={onChange}
          onSlideEnd={onMouseUp}
        >
          <Rail>{({ getRailProps }) => <RailStyle {...getRailProps()} />}</Rail>
          <Handles>
            {({ handles, getHandleProps }) => (
              <div>
                {handles.map(handle => (
                  <Handle
                    key={handle.id}
                    handle={handle}
                    suffix={suffix}
                    getHandleProps={getHandleProps}
                  />
                ))}
              </div>
            )}
          </Handles>
          <Tracks right={false}>
            {({ tracks, getTrackProps }) => (
              <div className="slider-tracks">
                {tracks.map(({ id, source, target }) => (
                  <Track
                    key={id}
                    source={source}
                    target={target}
                    getTrackProps={getTrackProps}
                  />
                ))}
              </div>
            )}
          </Tracks>
        </Slider>
        {showScale && (
          <Scale>
            <div>{suffix ? `${min}${suffix}` : min}</div>
            <div>{suffix ? `${max}${suffix}` : max}</div>
          </Scale>
        )}
      </Wrapper>
    )
  }
}

export default RangeClass
