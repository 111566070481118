import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import space from 'styled-system'
import media from '../../styles/media'
import { H2, Copy } from '../Text'
import Input from '../Input'
import Button from '../Buttons'
import Section from '../Section'
import Inbox from '../../images/inbox.svg'

const StyledDiv = styled.div`
  display: flex;
  margin-top: 3.2rem;
  justify-content: center;
  flex-direction: row;
`

const ImgBox = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 21rem;
  margin-top: 6.4rem;
  ${media.up.md`
    margin-top: 8rem;
    max-width: 100%;
  `}
`

const Newsletter = () => {
  const [email, setEmail] = useState(null)
  const queryString =
    typeof window !== 'undefined' ? window.location.search : ''
  return (
    <Section
      background={props => props.theme.colors.secondary}
      noPadding
      section="NEWSLETTER"
      headline="Unser kostenloser Anschlussfinanzierungs-Newsletter"
      copy="Sie wollen beim Thema Anschlussfinanzierung stets mit aktuellen und exklusiven Informationen auf dem Laufenden gehalten werden? Abonnieren Sie jetzt unseren Anschlussfinanzierungs-Newsletter!"
    >
      <Container>
        <Row>
          <Col lg={8} lgOffset={2}>
            <StyledDiv>
              {/* <Input
                label="Ihre E-Mail"
                labelTriggered={email}
                name="name"
                value={email}
                onChange={e => setEmail(e.target.value)}
              /> */}
              {typeof window !== 'undefined' && (
                <Button
                  ml={{ md: 3 }}
                  mt={{ xxs: 3, md: 0 }}
                  href={`http://www.interhyp.de/newsletter${queryString || ''}`}
                  target="_blank"
                  onClick={() => {
                    if (utag) {
                      utag.link({
                        event_action: 'newsletter_registration',
                        event_linktype: 'internal',
                        event_source: 'schuldenfrei_tag_interhyp',
                        event_target:
                          '/interhyp/servlet/interhyp?view=newsletterRequest&STYLE=b2c',
                        event_title: 'Jetzt abonnieren',
                      })
                    }
                  }}
                >
                  {' '}
                  Newsletter abonnieren{' '}
                </Button>
              )}
            </StyledDiv>
          </Col>
        </Row>
        <Row>
          <Col>
            <ImgBox src={Inbox} />
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

export default Newsletter
