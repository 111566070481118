import React, { Component } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { withPrefix } from 'gatsby'
// import ReactMapGL, {
//   Marker,
//   FlyToInterpolator,
//   BaseControl,
// } from 'react-map-gl'
import DeckGL, { GeoJsonLayer } from 'deck.gl'
import bbox from '@turf/bbox'
import pointInPolygon from '@turf/boolean-point-in-polygon'
import { point } from '@turf/helpers'
import center from '@turf/center'
import { featureEach } from '@turf/meta'
import WebMercatorViewport from 'viewport-mercator-project'
import { easeQuad } from 'd3-ease'
import styled, { keyframes } from 'styled-components'
import Scrollchor from 'react-scrollchor'

import interpolateRGB from 'interpolate-rgb'
import interpolateArrays from 'interpolate-arrays'
import MapSearch from '../MapSearch'
import MapDetails from '../MapDetails'
import MapInfoButton from '../MapInfoButton'
import pin from '../../images/pin.svg'
import media from '../../styles/media'
import Spinner from '../Spinner'

let ReactMapGL = () => null
let Marker = () => null
let FlyToInterpolator = () => null
let BaseControl = () => null

const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${media.up.lg`
    flex-direction: row;
    `}
`

const MapContainer = styled.div`
  position: relative;
  width: 100%;
  height: 30rem;
  ${media.up.sm`
    height: 58.5rem;
  `}
  ${media.up.md`
    height: 69.6rem;
  `}
`

const Sidebar = styled.div`
  flex-shrink: 0;
  ${media.up.lg`
    flex-basis: 42.7rem;
  `}
`

const Pin = styled.div`
  pointer-events: none;
  transform: translate(-50%, -100%);
`

const InfoButton = styled.div`
  position: absolute;
  bottom: 3rem;
`

export default class Map extends Component {
  state = {
    geojson: null,
    viewport: null,
    selectedFeature: null,
    selectedLayer: null,
    addrressLoaction: null,
    loadingMap: true,
    loadingGeoJson: true,
    fileDownloadPercentage: 0,
    fileDonwloadComplete: false,
  }

  mapRef = null

  containerRef = React.createRef()

  constructor(props) {
    super(props)
    this.updateDimensions = this.updateDimensions.bind(this)
    this.handleDetailsClose = this.handleDetailsClose.bind(this)
    this.handleViewportChange = this.handleViewportChange.bind(this)
    this.handleGeocoderViewportChange = this.handleGeocoderViewportChange.bind(
      this
    )
  }

  async componentDidMount() {
    const module = await import('react-map-gl')
    ReactMapGL = module.default
    Marker = module.Marker
    FlyToInterpolator = module.FlyToInterpolator
    BaseControl = module.BaseControl

    await axios
      .get(withPrefix('/kreise.json'))
      .then(response => {
        const maxNum = Math.max(
          ...response.data.features.map(
            o =>
              typeof o.properties.Zeitersparnis_Monate !== 'undefined' &&
              o.properties.Zeitersparnis_Monate
          ),
          0
        )
        const minNum = Math.min(
          ...response.data.features.map(
            o =>
              typeof o.properties.Zeitersparnis_Monate !== 'undefined' &&
              o.properties.Zeitersparnis_Monate
          ),
          0
        )
        this.setState({
          ...this.state,
          infoIsOpen: false,
          loadingGeoJson: false,
          fileDonwloadComplete: true,
          geojson: new GeoJsonLayer({
            id: 'search-result',
            data: response.data,
            getFillColor: p => {
              const num = Math.round(p.properties.Zeitersparnis_Monate)
              if (num >= 86) {
                return [0, 141, 54]
              }
              if (num >= 71) {
                return [149, 193, 31]
              }
              if (num >= 56) {
                return [217, 224, 117]
              }
              if (num >= 41) {
                return [255, 222, 0]
              }
              if (num >= 0) {
                return [238, 121, 4]
              }
              return [230, 230, 230]
            },
            getRadius: 1000,
            pointRadiusMinPixels: 10,
            pointRadiusMaxPixels: 10,
            pickable: true,
            getLineColor: [255, 255, 255, 255],
            getLineWidth: 2,
            stroked: true,
            filled: true,
            lineWidthScale: 1,
            lineWidthMinPixels: 0.5,

            // Update app state
            // onHover: ({ object, x, y }) => {
            //   // console.log("hello", object, x, y);
            // },
            onClick: e => {
              if (e.object.properties.Darlehenssumme) {
                this.setState({
                  ...this.state,
                  selectedFeature: e.object,
                  addrressLoaction: null,
                  viewport: this.getBoundingBoxViewport(e.object.geometry),
                  selectedLayer: new GeoJsonLayer({
                    id: 'geojson-layer2',
                    data: e.object,
                    pickable: false,
                    stroked: true,
                    filled: false,
                    lineWidthScale: 20,
                    lineWidthMinPixels: 2,
                    lineWidthMaxPixels: 16,
                    getLineColor: [255, 255, 255, 255],
                    getLineWidth: 16,
                  }),
                })
              }
            },
          }),
        })
      })
      .catch(function(error) {
        // handle error
        console.log(error)
      })
      .then(function() {
        // always executed
      })
    window.addEventListener('resize', this.updateDimensions)

    this.setState({
      ...this.state,
      loadingMap: false,
      viewport: new WebMercatorViewport({
        width: this.containerRef.current.clientWidth,
        height: this.containerRef.current.clientHeight,
      }).fitBounds(
        [
          [5.98865807458, 47.3024876979],
          [15.0169958839, 54.983104153],
        ],
        {
          padding: 10,
          offset: [0, 0],
        }
      ),
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.selectedFeature &&
      this.state.selectedFeature != prevState.selectedFeature
    ) {
      this._back.simulateClick()
      // tracking
      if (utag) {
        utag.link({
          event_action: 'deutschlandskarte_click',
          event_linktype: 'internal',
          event_source: 'schuldenfrei_tag_interhyp',
          event_target: 'schuldenfrei_tag_interhyp',
          event_title: 'Jetzt Sparpotenzial entdecken',
        })
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions)
  }

  getBoundingBoxViewport(geometry) {
    const box = bbox(geometry)
    const v = new WebMercatorViewport({
      width: this.mapRef.current._width,
      height: this.mapRef.current._height,
    }).fitBounds(
      [
        [box[0], box[1]],
        [box[2], box[3]],
      ],
      {
        padding: 20,
        offset: [0, -100],
      }
    )
    return {
      ...v,
      transitionDuration: 1000,
      transitionInterpolator: new FlyToInterpolator(),
      transitionEasing: easeQuad,
    }
  }

  handleGeocoderViewportChange = viewport => {
    const { geojson } = this.state
    let foundFeature = null
    featureEach(geojson.props.data, (feature, index) => {
      if (
        pointInPolygon(point([viewport.longitude, viewport.latitude]), feature)
      ) {
        foundFeature = feature
      }
    })

    if (foundFeature) {
      this.setState(prevState => ({
        ...prevState,
        viewport: {
          ...this.getBoundingBoxViewport(foundFeature),
          width: this.containerRef.current.clientWidth,
          height: this.containerRef.current.clientHeight,
        },
        selectedFeature: foundFeature,
        addrressLoaction: {
          latitude: viewport.latitude,
          longitude: viewport.longitude,
        },
        selectedLayer: new GeoJsonLayer({
          id: 'geojson-layer2',
          data: foundFeature,
          pickable: false,
          stroked: true,
          filled: false,
          lineWidthScale: 20,
          lineWidthMinPixels: 2,
          getLineColor: [255, 255, 255, 255],
          getLineWidth: 8,
        }),
      }))
    }
  }

  setDefaultViewport = () => {
    const { width, height, ...rest } = this.state.viewport
    this.setState({
      ...this.state,
      viewport: {
        ...rest,
        width: this.containerRef.current
          ? this.containerRef.current.clientWidth
          : 100,
        height: this.containerRef.current
          ? this.containerRef.current.clientHeight
          : 100,
      },
    })
    // this.setState(state => ({
    //   ...state,
    //   viewport: {
    //     ...state.viewport,
    //     width: '100%',
    //     height: '100%',
    //   },
    // }))
  }

  handleViewportChange = viewport => {
    const { width, height, ...rest } = viewport
    this.setState(prevState => ({
      ...prevState,
      viewport: {
        ...viewport,
        // width: 1000,
        // height: 800,
      },
    }))
  }

  updateDimensions() {
    this.setState({
      ...this.state,
      viewport: {
        ...this.state.viewport,
        width: this.containerRef.current.clientWidth,
        height: this.containerRef.current.clientHeight,
      },
    })
  }

  handleDetailsClose() {
    this.setState(prevState => ({
      ...prevState,
      viewport: {
        ...new WebMercatorViewport({
          width: this.containerRef.current.clientWidth,
          height: this.containerRef.current.clientHeight,
        }).fitBounds(
          [
            [5.98865807458, 47.3024876979],
            [15.0169958839, 54.983104153],
          ],
          {
            padding: 10,
            offset: [0, 0],
          }
        ),
        transitionDuration: 1000,
        transitionInterpolator: new FlyToInterpolator(),
        transitionEasing: easeQuad,
      },
      selectedFeature: null,
      addrressLoaction: null,
      selectedLayer: null,
    }))
  }

  render() {
    const {
      infoIsOpen,
      geojson,
      mapStyle,
      selectedFeature,
      viewport,
      addrressLoaction,
      selectedLayer,
      loadingMap,
      loadingGeoJson,
      fileDownloadPercentage,
      fileDonwloadComplete,
    } = this.state

    return (
      <div id="map">
        <Container>
          <MapContainer ref={this.containerRef}>
            {!fileDonwloadComplete ? (
              <Spinner />
            ) : (
              <ReactMapGL
                // width={1000}
                // height={800}
                // width="100%"
                // height="100%"
                style={{ position: 'absolute' }}
                ref={ref => {
                  this.mapRef = { current: ref }
                }}
                {...viewport}
                onViewportChange={this.handleViewportChange}
                mapboxApiAccessToken="pk.eyJ1IjoibGFyc2tlbW5pdHoiLCJhIjoiY2lzYTM5c3ZtMDAweDJ1cDVueDA3aDVobiJ9.8Uvjc-YItZYfB-ZVaI7LtQ"
                mapStyle="mapbox://styles/larskemnitz/ck0qpc1tz088f1cqsftld1cqx"
                onResize={this.setDefaultViewport}
                scrollZoom={false}
                maxZoom={7}
              >
                {geojson && (
                  <DeckGL
                    {...viewport}
                    layers={[geojson, selectedLayer]}
                    // style={{ opacity: selectedLayer ? 0.5 : 1 }}
                  />
                )}
                {addrressLoaction && (
                  <Marker {...addrressLoaction} offsetLeft={0} offsetTop={0}>
                    <Pin>
                      <img src={pin} alt="pin" />
                    </Pin>
                  </Marker>
                )}
              </ReactMapGL>
            )}
            <InfoButton>
              <MapInfoButton
                onClick={() => this.setState({ infoIsOpen: !infoIsOpen })}
                isOpen={infoIsOpen}
              />
            </InfoButton>
          </MapContainer>
          <Sidebar>
            <Scrollchor ref={ref => (this._back = ref)} to="#map" />
            {selectedFeature ? (
              <MapDetails
                data={selectedFeature.properties}
                onClose={this.handleDetailsClose}
              />
            ) : (
              <MapSearch
                mapRef={this.mapRef}
                onViewportChange={this.handleGeocoderViewportChange}
              />
            )}
          </Sidebar>
        </Container>
      </div>
    )
  }
}
