import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import media from '../../styles/media'
import Section from '../Section'
import Slider from '../Slider'
import Testimonial from '../Testimonial'
import Avatar from '../../images/avatar1.svg'

const Box = styled.div`
  background: ${props => props.theme.colors.primary};
  box-shadow: 4px 4px 1px 0 rgba(247, 171, 12, 0.3);
  padding: 2.2rem 1.6rem;
  ${media.up.sm`

  padding: 3.2rem 6.4rem;
  `}
`

const FAQ = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          internal: { mediaType: { eq: "text/markdown" } }
          sourceInstanceName: { eq: "testimonials" }
        }
      ) {
        edges {
          node {
            childMarkdownRemark {
              rawMarkdownBody
              frontmatter {
                name
                city
                consultant
                img {
                  childImageSharp {
                    # Specify the image processing specifications right in the query.
                    # Makes it trivial to update as your page's design changes.
                    fluid(maxWidth: 144, maxHeight: 144, cropFocus: CENTER) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const testimonials = data.allFile.edges
  return (
    <Section section="Testimonials" headline="Kundenerfahrungen">
      <Container>
        <Slider>
          {testimonials.map(
            (
              {
                node: {
                  childMarkdownRemark: {
                    rawMarkdownBody: text,
                    frontmatter: {
                      city,
                      consultant,
                      img: {
                        childImageSharp: { fluid: img },
                      },
                      name,
                    },
                  },
                },
              },
              key
            ) => (
              <Testimonial
                key={key}
                avatar={img}
                name={name}
                city={city}
                consultant={consultant}
                text={text}
              />
            )
          )}
        </Slider>
      </Container>
    </Section>
  )
}

export default FAQ
