import React from 'react'
import styled, { keyframes } from 'styled-components'

const animation = keyframes`
0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Spinner = styled.div`
  display: inline-block;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  :after {
    content: ' ';
    display: block;
    width: ${({ size }) => size};
    height: ${({ size }) => size};
    margin: 1px;
    border-radius: 50%;
    border: 5px solid
      ${props => (props.color ? props.color : props.theme.colors.primary)};
    border-color: ${props =>
        props.color ? props.color : props.theme.colors.primary}
      transparent
      ${props => (props.color ? props.color : props.theme.colors.primary)}
      transparent;
    animation: ${animation} 1.2s ease-in-out infinite;
  }
`

const index = ({ color, size = '6.4rem' }) => (
  <Spinner color={color} size={size} />
)

export default index
